<template>
  <div class="login-form">
    <!-- Captcha -->
    <form class="form-group" @submit.prevent="verifySubmit">
      <div v-if="formError" class="error-container">
        <p class="error">{{ formError }}</p>
      </div>
      <div v-if="isCaptchaEnabled && captchaImage">
        <label class="login-label">{{ $t("Please enter the numbers you see in the image") }}</label>
        <div
          v-if="captchaImage"
          :style="{
            backgroundImage: 'url(' + captchaImage + ')',
            'background-repeat': 'no-repeat',
            'background-color': 'white',
            width: '150px',
          }"
          class="captcha-image"
        ></div>
      </div>

      <div v-if="isCaptchaEnabled">
        <button @click="getAnotherCaptcha" class="button-link" type="button">
          {{ $t("Click to refresh if you can not read") }}
        </button>
      </div>

      <div class="form-control" v-if="isCaptchaEnabled">
        <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
          <!-- <label class="login-label">{{ $t("The result") }}</label>
          <span class="required">*</span> -->
        </div>
        <div class="input-container" dir="ltr">
          <input
            id="verifyCaptaha"
            type="text"
            v-model="verifyCaptaha"
           :placeholder="`${this.$t('The result')}`"
            @keydown.space.prevent
          />
        </div>
      </div>
      <br />
      <div class="login-button">
        <button class="button-primary login" type="submit">
           <span class="btn-txt-color">{{ $t("verify") }}</span>
        </button>
      </div>
    </form>
    <div v-if="isloading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    formData: {
      type: Object,
    },
  },

  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  data() {
    return {
      captchaImage: true,
      isCaptchaEnabled: true,
      localDisplayLang: null,
      captchaRefId: null,
      verifyCaptaha: null,
      formError: null,
      isloading: false,
    };
  },

  created() {
    console.log("THE VERIFY EMAIL CAPTCHA -- ", this.formData);

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.getCaptchaImage();
    // this.captchaPlaceholder = this.$t("The result");
    // eventBus.$on("resend-response", (response) => {
    //   if (this.formData.email) {
    //     if (response.reason) {
    //       this.formError = this.$t(response.reason);
    //     } else {
    //       let template = {
    //         //   input: this.userMobileno,
    //         formType: "lookup",
    //       };

    //       let snackbarPayload = {
    //         state: true,
    //         message: "Verification Email has been sent to your Email ID",
    //         color: "#ff9600",
    //       };

    //       eventBus.$emit("snackBarMessage", snackbarPayload);

    //       // snackbar.show({
    //       //   text: this.$t(response.reason),
    //       //   textColor: "#ffffff",
    //       //   pos: "bottom-center",
    //       //   actionTextColor: "#de2600",
    //       // });

    //       this.$emit("change", template);
    //     }
    //   }
    // });
  },

  methods: {
    ...mapActions(["getCaptcha", "resendLink"]),

    verifySubmit() {
      this.isloading = true;
      if (!this.verifyCaptaha) {
        this.formError = this.$t("Please enter Captcha");
        this.isloading = false;
        return;
      }

      const resendCaptcha = {};

      if (this.formData.email) {
        resendCaptcha.email = this.formData.email;
      } else if (this.formData.mobileno) {
        resendCaptcha.mobileno = this.formData.mobileno;
      }

      resendCaptcha.captcha = JSON.stringify({
        text: this.verifyCaptaha,
        referenceid: this.captchaRefId,
      });

      this.resendLink(resendCaptcha)
        .then((response) => {
          console.log("THE LOG FROM INSIDE THE COMPONENT ---> ", response, this.formData);
          if (response.data.errorcode) {
            this.formError = this.$t(response.data.reason);
            this.isloading = false;
          } else {
          console.log("THE LOG FROM INSIDE THE COMPONENT ---> 2",  this.formData);

            if (this.formData.input === 'email' && this.formData.email) {
              let template = {
                formType: "lookup",
              };

              this.$emit("change", template);
            } else if (this.formData.mobileno) {
              let template = {
                input: this.formData.mobileno,
                formType: "mobile",
                isResend: true,
              };
              this.$emit("change", template);
            }
            this.isloading = false;
          }
        })
        .catch((err) => {
          console.log("This is a resend Error -- > ", err);
          this.isloading = false;
        });
    },

    //   const newPayload = {
    //     ...this.formData.data.payload,
    //   };
    //   newPayload.params.captcha = JSON.stringify({
    //     text: this.verifyCaptaha,
    //     referenceid: this.captchaRefId,
    //   });

    //   if (this.formData.data.isSocialSignup) {
    //     eventBus.$emit("subscriberSignup", newPayload);
    //   } else {
    //     eventBus.$emit("subscriberLogin", newPayload);
    //   }
    // },

    getAnotherCaptcha() {
      this.getCaptchaImage();
    },

    getCaptchaImage() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.formError = this.$t(data.reason);
            return;
          }

          console.log("CAPCTHA PAYLOAD ---- ", data);

          this.captchaImage = data.payload;
          this.captchaRefId = data.referenceid;
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });
    },
  },

  beforeDestroy() {
    eventBus.$off("social-login-response-captcha-error");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.captcha {
  &-image {
    margin: 10px 0;
    height: 50px;
  }
}

.login-form {
  .otp_error_text {
    color: #ffffff;
    opacity: 0.5;
    font-family: $font-regular;
    line-height: 14px;
    margin-bottom: 20px;
    font-size: 10px;
    font-style: italic;
  }
  &-resendLink {
    &-text {
      font-size: 12px;
      font-family: $font-regular;
      color: red;
      span {
        text-decoration: underline;
        font-weight: bold;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }
  &-notreceived {
    opacity: 0.5;
    color: #efeff4;
    font-family: $font-regular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }
  &-resend {
    color: #efeff4;
    font-family: $font-regular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    cursor: pointer;
  }
  &-timer {
    color: #efeff4;
    font-family: $font-regular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }
}
.forgot-password-span {
  opacity: 0.5;
  color: $clr-light-gd3;
  font-family: $font-regular;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2rem;
  cursor: pointer;
}
.login-button {
  margin-top: 0.6rem;
  .login {
    width: 100%;
  }
}

.py-l {
  padding-left: 3.4rem !important;
}

.py-r {
  padding-right: 3.4rem !important;
}

.radio input[type="radio"] {
  display: none;
  /*removes original button*/
}

.radio label {
  position: relative;
  padding-right: 1.2rem;
  color: $clr-popup-label;
}

.radio input[type="radio"] + label:before {
  /*styles outer circle*/
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 11px;
  border: 2px solid $clr-popup-label;
  background-color: transparent;
}

.radio input[type="radio"]:checked + label {
  color: #ffffff;
}

.radio input[type="radio"]:checked + label:before {
  border: 2px solid $btn-clr;
}

.radio input[type="radio"]:checked + label > span {
  border-radius: 11px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: block;
  background-color: $btn-clr;
}

.rtl_radio input[type="radio"] {
  display: none;
  /*removes original button*/
}

.rtl_radio label {
  position: relative;
  padding-right: 1.2rem;
  color: $clr-popup-label;
}

.rtl_radio input[type="radio"] + label:before {
  /*styles outer circle*/
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 11px;
  border: 2px solid $clr-popup-label;
  background-color: transparent;
}

.rtl_radio input[type="radio"]:checked + label {
  color: #ffffff;
}

.rtl_radio input[type="radio"]:checked + label:before {
  border: 2px solid $btn-clr;
}

.rtl_radio input[type="radio"]:checked + label > span {
  border-radius: 11px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 5px;
  right: 29px;
  display: block;
  background-color: $btn-clr;
}

.input-container {
  position: relative;
  .rtl-change {
    color: #ffffff;
    position: absolute;
    top: 32%;
    left: 3%;
  }

  .rtl-eye {
    position: absolute;
    top: 35%;
    left: 3%;
    opacity: 0.5;
    img {
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
}
</style>
