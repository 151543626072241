<template>
  <div class="lookup-form-container">
    <form @submit.prevent="lookUpSubmit">
      <div class="form-group">
        <div v-if="formError" :dir="localDisplayLang === 'ara' ? 'ltr' : ''" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
        <div class="form-control">
          <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <label class="login-label">{{ $t("Email/mobile") }}</label>
            <!-- <label class="login-label">Email ID</label> -->
            <span class="required">*</span>
          </div>
          <div id="input-container" class="lookup-form-container-input-container" dir="ltr">
            <div v-if="field === 'Mobile'" id="countrycode" class="lookup-form-container-input-container-select-code">
              <input
                type="text"
                v-model="teleCode"
                placeholder="+91"
                :class="[localDisplayLang === 'ara' ? 'rightPad' : '']"
                disabled
              />
              <img id="arrow" src="@/assets/icons/down_arrow.svg" alt />
            </div>
            <input
              id="userEmail"
              class="lookup-form-container-input-container-inputtext"
              type="text"
              dir="ltr"
              v-model="userInput"
              autocomplete="false"
              @keydown.space.prevent
            />
          </div>
          <div class="lookup-form-container-dropselect" v-if="isDropSelect" dir="ltr">
            <div class="lookup-form-container-dropselect-search" id="countrylist-searchtext">
              <input
                type="text"
                class="lookup-form-container-dropselect-search-text"
                v-model="searchText"
                :placeholder="`${searchCountryPlaceholder}`"
                @input="filterCountry"
              />
            </div>
            <div class="lookup-form-container-dropselect-container">
              <div
                class="lookup-form-container-dropselect-container-item"
                id="countrylist-item"
                v-for="(item, index) in countryList"
                :key="index"
                @click="selectCode(item.dial_code)"
              >
                <div
                  class="lookup-form-container-dropselect-container-item-span"
                  @mouseover="hover($event)"
                  @mouseleave="out($event)"
                >
                  <span>{{ item.dial_code }}</span>
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lookup-form-container-continue-button">
          <button
            class="button-primary login"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            id="lookupbutton"
            type="submit"
          >
           <span class="btn-txt-color"> {{ $t("continue") }} </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapGetters } from "vuex";
import { store } from "@/store/store";

export default {
  name: "lookup",
  props: {
    input: {
      type: String,
    },
  },
  data() {
    return {
      i18nEmailIdLabel: "",
      i18nMobilenumberLabel: "",
      searchCountryPlaceholder: "",
      payloadData: "",
      userInput: "",
      formError: "",
      switcher: "Mobile",
      field: "Email",
      teleCode: null,
      countrycode: "Please Select Country Code",
      countryCodes: [],
      digitCount: 0,
      isDropSelect: false,
      searchText: "",
      countryList: [],
			countryListCopy: [],
      providerUniqueId: _providerId,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["getRtl", "getCountry", "getCountryCodesList"]),
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = "";
        }, 5000);
      }
    },
    userInput(val) {
      if (val) {
        this.verifyInput(val);
      } else {
        this.verifyInput("xyz");
        this.searchText = "";
        this.filterCountry();
        this.fetchCurrentCountryCode();
      }
    },
    // field(val) {
    //   if (val === "Mobile") {
    //     this.teleCode = "+91";
    //   }
    // }
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.getCountryList();

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    // FOr Test purpose

    // let info = {
    //   input: this.field === "Mobile" ? this.teleCode + this.userInput : this.userInput,
    //   inputtype: this.field,
    // };
    // info.formType = "socialCaptcha";
    // this.$emit("change", info);

    // -------

    eventBus.$on("social-captch-event", (data) => {
      console.log("THIS IS THE SOCIAL -- EVENT -- ", data);

      let info = {
        data
      };
      info.formType = "socialCaptcha";
      // this.formInfo = info;
      this.$emit("change", info);

    });

    this.i18nEmailIdLabel = this.$t("email id");
    this.i18nMobilenumberLabel = this.$t("mobilenumber");
    this.searchCountryPlaceholder = this.$t("search country");

    let self = this;
    window.addEventListener("mouseup", function(event) {
      if (self.field === "Mobile") {
        if (event.target.parentNode.id === "countrycode") {
          self.toggleDropSelect();
        } else if (!event.target.parentNode.id || event.target.parentNode.id === "input-container") {
          if (self.isDropSelect) {
            self.isDropSelect = false;
            self.searchText = "";
            self.filterCountry();
            document.getElementById("arrow").style.transform = "rotate(0deg)";
          }
        }
      }
    });

    this.lookUpform();

    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("lookup-response", (response) => {
      if (response.reason) {
        this.formError = response.errorcode == 6066 ? "" : response.reason;
        // if (document.getElementById("lookupbutton")) {
        //   document.getElementById("lookupbutton").click();
        // }
      } else {
        let info = {
          input: this.field === "Mobile" ? this.teleCode + this.userInput : this.userInput,
          inputtype: this.field,
        };
        if (response.success) {
          info.formType = "login";
          this.$emit("change", info);
        } else {
          info.formType = "signup";
          this.$emit("change", info);
        }
      }
    });
  },
  methods: {
    getCountryList() {
      this.countryList = this.getCountryCodesList;
			this.countryListCopy = [...this.countryList];
      this.fetchCurrentCountryCode();
    },
    fetchCurrentCountryCode() {
      let index = this.countryList.findIndex((element) => {
        return element.code === this.getCountry.CountryCode;
      });

      console.log("THE LIST", this.countryList, index);

      if (index > -1) {
        this.teleCode = this.countryList[index].dial_code ? this.countryList[index].dial_code : "";
      }
    },
    lookUpform() {
      if (this.input) {
        this.userInput = this.input;
      }
    },
    verifyInput(value) {
      let digits = /^[0-9]+$/;
      let alphaNumeric = /^[0-9a-zA-Z]+$/;

      if (digits.test(value)) {
        this.field = "Mobile";
        console.log("entered mobile");
        document.getElementById("userEmail").setAttribute("maxLength", 15);
        document.getElementById("userEmail").style.width = "70%";
        if (document.getElementById("countrycode")) {
          document.getElementById("countrycode").style.display = "block";
          document.getElementById("countrycode").style.width = "30%";
        }
      } else if (alphaNumeric.test(value)) {
        this.field = "Email";
        this.isDropSelect = false;
        console.log("entered email");
        document.getElementById("userEmail").removeAttribute("maxLength");
        document.getElementById("userEmail").style.width = "100%";
        if (document.getElementById("countrycode")) {
          document.getElementById("countrycode").style.display = "none";
          document.getElementById("countrycode").style.width = "0%";
        }
      }
    },
    countryCodeSelect(code) {
      this.teleCode = code;
      document.getElementById("input-container").style.display = "block";
      document.getElementById("expanded-container").style.display = "none";
    },
    toggleDropSelect() {
      this.isDropSelect = !this.isDropSelect ? true : false;
      if (this.isDropSelect) {
        document.getElementById("arrow").style.transform = "rotate(-180deg)";
      } else {
        document.getElementById("arrow").style.transform = "rotate(0deg)";
      }
    },
    selectCode(code) {
      console.log("selected code", code);
      this.teleCode = "";
      this.teleCode = code;
      this.isDropSelect = false;

      document.getElementById("arrow").style.transform = "rotate(0deg)";
    },
    hover(e) {
      if (e.target.parentNode.className === "lookup-form-container-dropselect-container-item") {
        e.target.parentNode.style.backgroundColor = "rgba(0, 0, 0,0.3)";
      }
    },
    out(e) {
      if (e.target.parentNode.className === "lookup-form-container-dropselect-container-item") {
        e.target.parentNode.style.backgroundColor = "#131313";
      }
    },
    filterCountry() {
      let searchText = this.searchText;
      this.countryList = [];
			 if (
        searchText === "" ||
        searchText === null ||
        searchText === undefined
      ) {
        this.countryList = [...this.countryListCopy];
        return;
      }

      let arr = [];
      arr = this.countryListCopy.filter((element) => {
        return (
          element.name.toLowerCase().includes(searchText) ||
          element.dial_code.toLowerCase().includes(searchText)
        );
      });

      this.countryList = arr;
    },
    lookUpSubmit() {
      let payload = {};
      if (this.userInput.slice(0, 1) !== "+" && this.userInput.indexOf("%") <= 0) {
        if (this.field === "Email") {
          if (!this.validateEmail(this.userInput)) return;
          payload.email = this.userInput.trim();
        } else if (this.field === "Mobile") {
          payload.mobileno = this.teleCode + this.userInput;
        }
        eventBus.$emit("lookUp", payload);
      } else if (this.userInput.slice(0, 1) === "+") {
        this.formError = "Please remove '+' from Input";
      } else if (this.userInput.indexOf("%") > -1) {
        this.formError = "Valid Email Requireds";
      } else {
        // this.formError = "Please Enter Email ID / Mobile";
        this.formError = "Please Enter Email ID";
      }

      // if (this.userInput) {
      //   if (this.field === "Mobile") {
      //     payload.mobileno = this.teleCode + this.userInput;
      //   } else {
      //     payload.email = this.userInput;
      //   }
      //   eventBus.$emit("lookUp", payload);
      // } else {
      //   this.formError = "Please Enter Email ID / Mobile";
      // }
    },
  },
  mixins: [Utility],
  beforeDestroy() {
    eventBus.$off("mouseup");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
	// .btn-txt-continue {
	// 	text-transform: uppercase;
	// 	background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
	// 	-webkit-background-clip: text;
	// 	-webkit-text-fill-color: transparent;
	// }
.lookup-form-container {
  position: relative;
  &-input-container {
    display: flex;
    &-select-code {
      position: relative;
      z-index: 1001;
      width: 30%;
      // .rightPad {
      //   padding-right: 35px;
      // }
      cursor: pointer;
      img {
        position: absolute;
        top: 43%;
        right: 12%;
      }
    }
  }
  &-dropselect {
    position: absolute;
    top: 93%;
    background-color: #131313;
    width: 100%;
    border-radius: 0.2rem;
    &-search {
      input[type="text"] {
        border: none;
        margin: 0;
        padding: 0.7rem;
        opacity: 0.5;
        color: #f7f9fc;
        font-family: $font-regular;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
    &-container {
      max-height: 200px;
      overflow-y: scroll;
      &-item {
        padding: 0.7rem;
        position: relative;
        z-index: 1001;
        &-span {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          span {
            color: #f7f9fc;
            font-family: $font-regular;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            text-transform: uppercase;
            position: relative;
            z-index: -1;
          }
        }
      }
    }
  }
  &-expanded-container {
    display: none;
    &-expanded-select {
      width: 100%;
      color: #f7f9fc;
      font-family: $font-regular;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      padding: 0.7rem;
      -webkit-appearance: none;
      background: url("../../../../assets/icons/down_arrow.svg") no-repeat 97%;
      background-color: #131313;
      &-option {
        margin: 0.7rem;
        color: red;
      }
    }
  }
  &-mobile {
    padding-left: 25%;
  }
  &-continue-button {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .lookup-form-container {
    &-input-container {
      &-select-code {
        width: 30%;
      }
    }
    &-dropselect {
      &-search {
        input[type="text"] {
          font-size: 10px;
        }
      }
      &-item {
        &-span {
          span {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
